<script setup>
import useOauth from "@/components/ExternalApps/SalesChannelManagementApp/composables/useOauth";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { defineProps, ref } from "vue";

const props = defineProps({
  integration: {
    type: Object,
    default: () => {}
  }
});

const buttonClicked = ref(false);

const {
  authorizationUrl,
  ottoInvitationLinkButton,
  wasAlreadyInstalled,
  value,
  authSuccess,
  formConfig,
  form,
  selectedAppName
} = useOauth(props.integration);
</script>

<template>
  <div class="text-left">
    <div
      v-if="
        ottoInvitationLinkButton.length && !authSuccess && !wasAlreadyInstalled
      "
    >
      <div class="py-5">{{ $t("salesChannelManagementApp.steps") }} 1:</div>
      <div class="info-text">
        {{ $t("salesChannelManagementApp.otto.installationTitle") }}
      </div>
      <div class="d-flex align-items-center">
        <a
          :href="ottoInvitationLinkButton"
          class="btn btn-primary my-2"
          target="_blank"
          @click="buttonClicked = true"
        >
          {{ $t("salesChannelManagementApp.otto.installAction") }}
        </a>
      </div>
      <v-divider></v-divider>
      <div class="pb-5">{{ $t("salesChannelManagementApp.steps") }} 2:</div>
    </div>
    <FormHelper
      v-if="form.length && !authSuccess"
      v-model="value"
      :config="formConfig"
      :form="form"
    />
    <a
      v-if="!authSuccess"
      :href="authorizationUrl"
      class="btn btn-primary my-4"
      target="_parent"
      :class="{ disabled: !buttonClicked && !wasAlreadyInstalled }"
    >
      {{ $t(`salesChannelManagementApp.${selectedAppName}.oAuthFlowHelp`) }}
    </a>
    <div v-if="authSuccess" class="alert alert-success">
      {{ $t(`salesChannelManagementApp.${selectedAppName}.authSuccess`) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-text {
  color: #6c757d;
}
</style>
