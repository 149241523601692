import {
  amazonDefaultRegion,
  amazonRegions
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/amazonRegions";

export const oAuthConfig = [
  {
    name: "amazon",
    config: {
      form: [
        {
          type: "select",
          label: "amazon.region",
          name: "region",
          clearable: true,
          default: amazonDefaultRegion.value,
          options: amazonRegions,
          validations: {
            required: true
          }
        }
      ],
      params: ["application_id", "redirect_uri", "version"]
    }
  },
  {
    name: "shopify",
    config: {
      form: [
        {
          type: "text",
          label: "url",
          infoText: "urlHelp",
          snippetPrefix: "salesChannelManagementApp",
          name: "shop",
          clearable: true,
          validations: {
            required: true
          }
        }
      ],
      params: ["client_id", "redirect_uri"]
    }
  },
  {
    name: "otto",
    config: {
      form: [],
      params: ["client_id", "response_type", "scope"]
    }
  }
];

/**
 * @param url
 * @returns {*|string}
 */
const getBaseUrl = url => {
  try {
    return new URL(url).origin.replace(/\/+$/, "");
  } catch (error) {
    let authUrl = url?.substr(0, url.lastIndexOf("/")) || url;

    if (!authUrl.startsWith("https://")) {
      authUrl = "https://" + authUrl;
    }

    return authUrl;
  }
};

/**
 * @param url
 * @returns {void|string}
 */
export const getShopifyUrl = url => {
  if (!url?.length) return;

  // myshopify || custom domain (masked shopify domain)
  if (url?.includes(".myshopify") || URL.canParse(url)) {
    return getBaseUrl(url) + "/admin/oauth/authorize";
  }

  // shopify shop name (shop name or shopify domain)
  return `https://${url}.myshopify.com/admin/oauth/authorize`;
};
